
import Vue from "vue";
import moment from "moment";
import { InformationData, Session } from "@/types/interfaces";
import Api from "@/services/Api";

export default Vue.extend({
  name: "Program",
  props: {
    program: {
      type: Object as () => Session
    },
    profile: Object
  },
  data() {
    return {
      statuses: {
        pending: (this as any).$t("mentroingprogram.programStatusesPending"),
        ongoing: (this as any).$t("mentroingprogram.programStatusesOngoing"),
        completed: (this as any).$t("mentroingprogram.programStatusesCompleted")
      },
      programItems: [
        {
          name: "start_date",
          label: (this as any).$t("mentroingprogram.programItemsStartDate"),
          icon: "calendar",
          value: null
        },
        {
          name: "status",
          label: (this as any).$t("mentroingprogram.programItemsStatus"),
          icon: "status",
          value: null
        },
        {
          name: "price",
          label: (this as any).$t("mentroingprogram.programItemsStatus"),
          icon: "status",
          value: null
        }
      ] as InformationData[]
    };
  },
  computed: {
    getTitle(): string {
      return (this.program as any).type === "program"
        ? (this.program as any).title
        : (this.program as any).description;
    }
  },
  mounted(): void {
    // Change program date format
    //@ts-ignore
    const dateString = `${this.program.start_date}`;

    if ((this.program as any).type === "request") {
      this.programItems = this.programItems.filter(
        item => item.name !== "status"
      );
    } else if ((this.program as any).type === "program") {
      this.programItems = this.programItems.filter(
        item => item.name !== "price"
      );
    }

    for (const key of Object.keys(this.program)) {
      const index = this.programItems.findIndex(i => i.name === key);
      if (index > -1) {
        if (key === "start_date") {
          this.programItems[index].value = moment(dateString)
            .format("Do MMMM, yyyy")
            .toString();
          continue;
        } else if (key === "price") {
          this.programItems[index].value =
            this.program[key as keyof Session] + " USD";
        } else
          this.programItems[index].value = this.program[key as keyof Session];
      }
    }
  },
  methods: {
    profileLink(data: any) {
      if (data.type == "mentee") {
        return `/mentorees/${data.uuid}`;
      }
      if (data.type == "mentor") {
        return `/mentors/${data.uuid}`;
      }
    },
    getOtherUserProfile() {
      return this.profile.type === "mentor" ? "mentee" : "mentor";
    },
    openRequest(request: any) {
      this.$router.push(`mentoring-programs/create?MpRqiD=${request.id}`);
    },
    async declineProgramRequest(request: any) {
      try {
        await Api().get(`/program/request/${request.id}/decline`);
        (this as any).$alertify.success("Program declined successfully");
        this.$emit("refresh");
      } catch (errors) {
        console.error(errors);
      }
    }
  }
});
