
import Vue from "vue";
import SessionMenu from "@/components/Session/SessionMenu.vue";
import { mapGetters } from "vuex";
import Program from "@/components/MentoringPlan/Program.vue";
// import { programsData } from "../testData/data.module.js";
import MentoringProgramProposalModal from "../components/Modals/MentoringProgramProposalModal.vue";

export default Vue.extend({
  name: "MentoringProgramsPage",
  components: {
    MentoringProgramProposalModal,
    Program,
    SessionMenu
  },
  data() {
    return {
      loading: true,
      page: 1,
      load_more: false,
      tabs: ["All", "Ongoing", "Completed", "Requests"],
      currentTab: "All",
      filter: {},
      programs: [],
      activeProgram: null as any,
      status: "accept",
      data: [] as any
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  mounted() {
    this.getPrograms(this.page);
  },
  methods: {
    getPrograms(page: any, reset = false) {
      if (reset) {
        this.data = [];
        this.load_more = false;
      }
      this.loading = true;
      const data = {
        [this.profile.type === "mentor" ? "is_mine" : "is_mentee"]: true,
        user_type: this.profile.type,
        ...this.filter
      };
      this.$store
        .dispatch("program/GET_ALL_PROGRAM", {
          data: {
            ...data,
            type: this.currentTab
          },
          page
        })
        .then(data => {
          this.page = page;
          this.load_more = page < data.last_page;
          this.data.push(...data.data);
          this.loading = false;
        });
    },
    onLoadMore() {
      this.page++;
      this.getPrograms(this.page);
    },
    onTab(tab: any) {
      this.page = 1;
      this.programs = [];
      this.data = [];
      this.currentTab = tab;
      this.getPrograms(this.page);
    },
    onSearch(filter: any) {
      this.programs = [];
      this.filter = filter;
      this.getPrograms(this.page);
    },
    setProgram(id: any, status: any) {
      const activeProgram = this.data.find((item: any) => item.id === id);
      this.activeProgram = activeProgram;
      if (this.$refs["program-proposal-modal"]) {
        //@ts-ignore
        this.$refs["program-proposal-modal"].setProgram(activeProgram);
        this.status = status;
        this.$nextTick(() => {
          this.$bvModal.show("mentoring-program-proposal");
        });
      }
    },
    changeStatus(newStatus: any) {
      this.status = newStatus;
    },
    declineProgram() {
      this.$store
        .dispatch("program/DECLINE_PROGRAM", this.activeProgram.id)
        .then(() => {
          this.activeProgram = null;
          this.$bvModal.hide("mentoring-program-proposal");
          this.$store.dispatch("program/GET_ALL_PROGRAMS");
        });
    }
  }
});
