
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "SessionMenu",
  props: {
    profile: Object,
    hideSearch: Boolean
  },
  data() {
    return {
      filter: {
        searchVal: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      moduleSettings: "helper/getModuleSettings"
    }),
    mentoringSessionStatus(): any {
      return this.moduleSettings["mentoring_session"] == 1;
    },
    mentorcastStatus(): any {
      return this.moduleSettings["mentorcast"] == 1;
    },
    mentoringProgramStatus(): any {
      return this.moduleSettings["mentoring_program"] == 1;
    },
    mentorLibraryStatus(): any {
      return this.moduleSettings["mentor_library"] == 1;
    },
    feedbackEngineStatus(): any {
      return this.moduleSettings["feedback_engine"] == 1;
    },
    mentorCommunityStatus(): any {
      return this.moduleSettings["mentor_community"] == 1;
    },
    sessionCertificateStatus(): any {
      return this.moduleSettings["session_certificate"] == 1;
    }
  },
  mounted(): void {
    this.$store.dispatch("helper/GET_MODULE_SETTINGS");
  },
  methods: {
    profileRoute() {
      const path = "/profile";
      if (this.$route.path !== path) this.$router.push(path);
    },
    handleSearch() {
      this.$emit("search", this.filter);
    }
  }
});
