
import Vue from "vue";
import PaypalCheckout from "@/components/Modals/PaypalCheckout.vue";
import StripeModal from "@/components/Modals/StripeModal.vue";
import ApplyDiscountModal from "@/components/Modals/ApplyDiscountModal.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "MentoringProgramProposalModal",
  components: {
    PaypalCheckout,
    StripeModal,
    ApplyDiscountModal
  },
  props: {
    status: {
      type: String
    }
  },
  data() {
    return {
      program: {} as any,
      paymentMethods: [
        {
          id: "paypal",
          card_type: "paypal",
          text: "",
          value: "paypal"
        },
        {
          id: "card",
          card_type: "credit_card",
          text: "Credit card (via Stripe)",
          value: "stripe"
        }
      ],
      infoData: [
        {
          name: "start_date",
          label: "Start Date",
          icon: "calendar"
        },
        {
          name: "is_free_library",
          label: "Free access to Library",
          icon: "books"
        },
        {
          name: "duration",
          label: "Duration",
          icon: "time-circle"
        },
        {
          name: "no_of_milestones",
          label: "Number of Milestones",
          icon: "milestone"
        },
        {
          name: "is_certificate",
          label: "Certificates",
          icon: "education"
        },
        {
          name: "price",
          label: "Program Price",
          icon: "paid"
        }
      ],
      i_agree: false,
      payBtnLoading: false,
      selectedPaymentMethod: null as any,
      paypalLoaded: false,
      data: {
        id: null,
        type: "program",
        payment_method_id: "",
        payment_method_type: "",
        //for paypal
        payer_id: "",
        discountable_id: null,
        discounted_price: null
      },
      cardTypeOptions: [
        { label: "Debit Card", value: "visa", icon: "visa" },
        { label: "Debit Card", value: "paypal", icon: "paypal" },
        { label: "Debit Card", value: "mastercard", icon: "mastercard" },
        { label: "Credit Card", value: "credit_card", icon: "credit-card" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      stripePaymentMethods: "stripePayment/getPaymentMethods",
      paymentTermsLink: "helper/getPaymentTermsLink"
    })
  },
  mounted() {
    this.$store.dispatch("stripePayment/GET_PAYMENT_METHODS");
  },
  methods: {
    modalClosed() {
      this.paypalLoaded = false;
      this.data.payment_method_id = "";
      this.i_agree = false;
      this.data.payment_method_type = "";
      this.data.discountable_id = null;
      this.data.discounted_price = null;
      this.selectedPaymentMethod = null;
    },
    setProgram(program: any) {
      this.program = program;
      this.data.id = program.id;
    },
    getData(item: any): any {
      if (item === "is_certificate" || item === "is_free_library") {
        return this.program[item] ? "Yes" : "No";
      }
      if (item in this.program) {
        return this.program[item];
      }
    },
    getIconType(type: string) {
      if (this.cardTypeOptions.find(i => i.value === type)) {
        //@ts-ignore
        return this.cardTypeOptions.find(i => i.value === type).icon;
      }
      return "paypal";
    },
    setAgree() {
      this.i_agree = !this.i_agree;
      if (
        this.i_agree &&
        this.data.payment_method_type == "paypal" &&
        !this.paypalLoaded
      ) {
        this.loadPaypal();
      }
    },
    setPaymentMethodType(type: any) {
      this.data.payment_method_type = type;

      console.log(type);
      if (type == "paypal" && this.i_agree && !this.paypalLoaded) {
        this.loadPaypal();
      }
    },
    loadPaypal() {
      //@ts-ignore
      this.$refs["paypal_checkout"].mount();
      this.paypalLoaded = true;
    },
    selectPaymentMethod() {
      const index = this.stripePaymentMethods.findIndex(
        (i: any) => i.id === this.data.payment_method_id
      );
      if (index > -1) {
        this.selectedPaymentMethod = this.stripePaymentMethods[index];
        (this.$refs["select-payment-method-modal"] as Vue & {
          hide: () => boolean;
        }).hide();
      }
    },
    onStripeModal() {
      this.$bvModal.show("stripe");
      //@ts-ignore
      this.$refs.stripe.loadStripe();
    },
    paypalCheckout(data: any) {
      this.data.payment_method_id = data.payment_id;
      this.data.payer_id = data.payer_id;
      this.pay();
    },
    pay() {
      this.payBtnLoading = true;

      this.$store
        .dispatch("booking/CREATE_BOOKING", this.data)
        .then(() => {
          this.payBtnLoading = false;
          this.selectedPaymentMethod = null;
          this.data.payment_method_id = "";
          // this.$bvModal.hide("payment");
          // this.$emit("complete", data.id);
          (this as any).$alertify.success(
            "Mentoring Program has been accepted successfully"
          );
          this.$emit("changeStatus", "accepted");
        })
        .catch(error => {
          this.payBtnLoading = false;
          if (error.response.status === 422) {
            for (const e of error.response.data.errors) {
              // @ts-ignore
              this.$alertify.error(e);
            }
          }
        });
    },
    onMyPrograms() {
      this.$bvModal.hide("mentoring-program-proposal");
      this.$emit("refresh");
    },
    onOpenProgram() {
      this.$router.push("/mentoring-programs/" + this.program.id);
    }
  }
});
